import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  Auth,
  GoogleAuthProvider,
  signInWithRedirect,
  connectAuthEmulator,
  setPersistence,
  browserLocalPersistence,
  UserCredential,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { ConfigService } from './configService';
import { ApiService } from './apiService';
import { RedirectService } from './redirectService';
import { getConfig } from '../config.ts';
import { LoginToAGWResponse } from '../types/common';
import { ToastService } from './toastService.ts';

export class AuthService {
  private constructor() {}

  static #instance: AuthService;

  #auth?: Auth;
  readonly #toastService: ToastService = ToastService.instance;
  readonly #configService: ConfigService = ConfigService.instance;

  readonly #useEmulator: boolean = JSON.parse(
    getConfig().FIREBASE_USE_AUTH_EMULATOR,
  ) as boolean;

  #emulatorConnected: boolean = false;

  initialized: Promise<boolean> = Promise.resolve(false);

  get provider() {
    return new GoogleAuthProvider();
  }

  loggedUser: LoginToAGWResponse | null = null;

  get auth(): Auth {
    return this.#auth!;
  }

  static get instance(): AuthService {
    if (!AuthService.#instance) {
      AuthService.#instance = new AuthService();
    }

    return AuthService.#instance;
  }

  #errorHandler(error: unknown) {
    console.error(error);
    this.#toastService.showErrorToast((error as Error)?.message ?? error);
  }

  initFirebase() {
    this.initialized = new Promise((resolve) => {
      this.#configService
        .getConfig()
        .then(async (config) => {
          initializeApp(config.firebase);
          this.#auth = getAuth();
          this.#auth.tenantId = config.firebase.tenantId;
          if (this.#useEmulator && !this.#emulatorConnected) {
            connectAuthEmulator(this.#auth, getConfig().FIREBASE_EMULATOR, {
              disableWarnings: true,
            });
            this.#emulatorConnected = true;
          }
          await this.#auth.currentUser?.getIdToken(true);
          resolve(true);
        })
        .catch((error) => {
          this.#errorHandler(error);
          resolve(false);
        });
    });
  }

  async handleLoginResponse(loginResult: UserCredential) {
    try {
      const idToken = await loginResult.user.getIdToken();
      this.loggedUser = await ApiService.instance.loginToAGW(idToken);
      await this.#verifyPermissions(this.loggedUser);

      if (window.parent === window.top) {
        RedirectService.instance.redirectToApp();
      }
    } catch (error) {
      this.#errorHandler(error);
    }
  }

  /**
   * Verifies if the correct permissions exist in the AGW response for the currently configured organization and application.
   * If the required permissions are not found, the user is signed out and an error is thrown.
   *
   * @param {LoginToAGWResponse} agwResponse - The response object from AGW after login, containing the permissions data for the company.
   * @return {Promise<void>} A promise that resolves if the required permissions are found, otherwise it rejects with an error.
   * @throws {Error} Throws an error if company permissions, organization permissions, or required application permissions are not found.
   */
  async #verifyPermissions(agwResponse: LoginToAGWResponse): Promise<void> {
    if (!agwResponse.companyPermissions) {
      await signOut(this.#auth as Auth);
      throw new Error('Company permissions not found.');
    }

    const companyPermissions = agwResponse.companyPermissions.find(
      (companyPermission) =>
        companyPermission.code === this.#configService.organizationCode,
    );

    if (!companyPermissions) {
      await signOut(this.#auth as Auth);
      throw new Error(
        `No permissions found for organization code "${this.#configService.organizationCode}"`,
      );
    }

    const appPermission = companyPermissions?.permissions.find(
      (permission) =>
        permission.name === `${this.#configService.activePermissionPrefix}_APP`,
    );
    if (!appPermission) {
      await signOut(this.#auth as Auth);
      throw new Error(
        `No permissions found for subject "${this.#configService.activePermissionPrefix}_APP"`,
      );
    }

    appPermission?.actions.find(
      (action) => action.name === this.#configService.loginPermission,
    );
    if (!appPermission) {
      await signOut(this.#auth as Auth);
      throw new Error(
        `No permissions found for action "${this.#configService.loginPermission}" in subject "${this.#configService.activeApplication}_APP"`,
      );
    }
  }

  async loginWithPassword(
    email: string,
    password: string,
  ): Promise<string | void> {
    try {
      const initialized = await this.initialized;
      if (!initialized) {
        throw new Error('Firebase not initialized');
      }

      const loginResult = await signInWithEmailAndPassword(
        this.#auth!,
        email,
        password,
      );
      this.handleLoginResponse(loginResult);
    } catch (error) {
      this.#errorHandler(error);
    }
  }

  async loginWithGoogle(): Promise<string | void> {
    try {
      const initialized = await this.initialized;
      if (!initialized) {
        throw new Error('Firebase not initialized');
      }

      await setPersistence(this.auth, browserLocalPersistence);
      await signInWithRedirect(this.auth, this.provider);
    } catch (error) {
      this.#errorHandler(error);
    }
  }

  async refreshAGWLogin(idToken: string): Promise<void> {
    this.loggedUser = await ApiService.instance.loginToAGW(idToken);
  }

  async logout(): Promise<void> {
    try {
      const initialized = await this.initialized;
      if (!initialized) {
        throw new Error('Firebase not initialized');
      }

      this.loggedUser = null;
      await signOut(this.#auth!);
    } catch (error) {
      this.#errorHandler(error);
    }
  }
}
