import { ConfigService } from '../services/configService';
import { ApplicationDto } from '../types/ApplicationDto';
import { ConfigDto } from '../types/ConfigDto';
import { OrganizationDto } from '../types/OrganizationDto';
import { getElement } from '../utls/getElement';

const setActiveOrganization = (organizationCode: string) => {
  const oraganizationsContainerElement = getElement<HTMLDivElement>(
    `#organizationsContainer`,
  );
  const organizationElement = getElement<HTMLDivElement>(
    `#organization-${organizationCode}`,
  );

  oraganizationsContainerElement.childNodes.forEach((node) => {
    (node as HTMLDivElement).classList.remove('bg-gray-200');
  });
  organizationElement.classList.add('bg-gray-200');
  ConfigService.instance.setOrganizationCode(organizationCode);
  renderApps();
};

const setActiveApplication = (application: string) => {
  const applicationsContainerElement = getElement<HTMLDivElement>(
    `#applicationsContainer`,
  );
  const organizationElement = getElement<HTMLDivElement>(
    `#application-${application}`,
  );

  applicationsContainerElement.childNodes.forEach((node) => {
    (node as HTMLDivElement).classList.remove('bg-gray-200');
  });
  organizationElement.classList.add('bg-gray-200');
  ConfigService.instance.setActiveApplication(application);
};

const getApplicationHtml = (applicationList: ApplicationDto[]) => {
  const activeOrganizationCode = ConfigService.instance.organizationCode;
  const activeApplication = ConfigService.instance.activeApplication;
  return applicationList
    .filter((app) => app.allowedTenants.includes(activeOrganizationCode))
    .reduce((acc, application) => {
      const activeClass =
        application.name === activeApplication ? ' bg-gray-200' : '';
      return (
        acc +
        `<div 
					class="flex flex-grow flex-col justify-center items-center text-center border-r last:border-r-0 p-3 py-1 cursor-pointer transition ease-in-out hover:bg-gray-200${activeClass}"
					id="application-${application.name}"
					data-cy="ula-application-${application.name.toLowerCase()}"
				>
          <div class="w-12 sm:w-20 rounded-full overflow-hidden">
            <img 
              src="/apps/${application.name.toLowerCase()}.svg"
              alt="${application.name} icon"
              class="w-12 sm:w-20 bg-white p-2"
            />
          </div>
					<div class="mt-1 font-semibold text-lg">${application.name}</div> 
				</div>`
      );
    }, '');
};

const getOrganizationHtml = (organizationList: OrganizationDto[]) => {
  const activeOrganizationCode = ConfigService.instance.organizationCode;
  return organizationList.reduce((acc, organization) => {
    const activeClass =
      organization.code === activeOrganizationCode ? ' bg-gray-200' : '';
    return (
      acc +
      `<div 
				class="flex flex-grow justify-start items-center sm:flex-col text-center border-b last:border-b-0 sm:border-b-0 sm:last:border-b-0 sm:border-r sm:last:border-r-0 py-2 sm:py-3 px-3 sm:px-6 cursor-pointer transition ease-in-out hover:bg-gray-200${activeClass}"  
				id="organization-${organization.code}"
				data-cy="ula-organization-${organization.code.toLocaleLowerCase()}"
			>
				<img 
					src="/tenants/${organization.code.toLowerCase()}.png"
					alt="${organization.name} logo"
					class="rounded-full max-w-12 sm:max-w-20"
				/>
				<div class="ml-3 sm:ml-0 sm:mt-3 font-semibold text-2xl">${organization.code}</div> 
			</div>`
    );
  }, '');
};

const renderApps = async () => {
  const apps = await ConfigService.instance.getApps();
  getElement<HTMLDivElement>('#applicationsContainer').innerHTML =
    getApplicationHtml(apps);

  getElement<HTMLDivElement>('#applicationsContainer').childNodes.forEach(
    (node) => {
      const nodeElement = node as HTMLDivElement;
      nodeElement.addEventListener('click', () => {
        const organizationCode = nodeElement.id.split('-')[1];
        setActiveApplication(organizationCode);
      });
    },
  );
};

const render = (firebaseConfigs: ConfigDto[]) => {
  const organizations = getOrganizationHtml(
    firebaseConfigs.map((config) => config.organization),
  );

  return `
	<div class="flex justify-center items-center min-h-screen">
    <div class="max-w-screen-xl mt-6 flex flex-col justify-center items-center">
			<img src="/dbg_logo_with_text.png" alt="DBG logo" class="w-full sm:max-w-xl" />
      <div class="w-full sm:max-w-screen-xl px-3">
      <div 
				class="flex flex-col sm:flex-row border rounded-lg"  
				id="organizationsContainer"
			>${organizations}</div>
      </div>
      <div class="w-full sm:w-auto sm:max-w-md mt-3 px-3">
        <div class="border flex rounded-lg" id="applicationsContainer"></div>
      </div>
			<div class="mt-9 mb-3">
				<button 
					id="googleLogin"
					class="flex items-center justify-center p-3 border rounded-lg transition hover:bg-gray-200 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
				><img class="h-8 mr-2" src="/google-logo.svg" />Sign in with google</button>
			</div>
			<div class="border-b w-96 my-3"></div>
			<form class="w-2/3 my-3 mb-6" id="loginForm" data-cy="ula-login-form" novalidate>
				<div>
					<label
						for="${formInputId.email}"
						class="block text-sm font-medium leading-6 text-gray-900"
					>Email</label>
					<input
            id="${formInputId.email}"
						data-cy="ula-email"
						name="email"
						type="email"
						autocomplete="email"
						class="py-1.5 px-2 block w-full rounded ring-1 ring-inset ring-gray-300 focus:outline-gray-900"
					/>
          <div id="${formInputId.email}-error" class="transition-all text-sm text-red-600 overflow-hidden opacity-0">&nbsp;</div>
				</div>

				<div class="mt-1">
					<label 
						for="${formInputId.password}"
						class="block text-sm font-medium leading-6 text-gray-900"
						>Password</label>
          <input
            id="${formInputId.password}"
						data-cy="ula-password"
						name="password"
						type="password"
						autocomplete="current-password"
						class="py-1.5 px-2 block w-full rounded ring-1 ring-inset ring-gray-300 focus:outline-gray-900"
					/>
          <div id="${formInputId.password}-error" class="transition-all text-sm text-red-600 overflow-hidden opacity-0">&nbsp;</div>
				</div>

				<div class="mt-3 text-right">
					<button
							type="submit"
							data-cy="ula-submit-login-btn"
							class=" justify-center items-center rounded-md p-3 border text-sm font-semibold transition hover:bg-gray-200 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
					>Sign in</button>
				</div>
			</form>
    </div>
  </div>
`;
};

export const renderLoginForm = async (app: HTMLDivElement) => {
  const configs = await ConfigService.instance.getFirebaseConfigs();
  app.innerHTML = render(configs);
  getElement<HTMLDivElement>('#organizationsContainer').childNodes.forEach(
    (node) => {
      const nodeElement = node as HTMLDivElement;
      nodeElement.addEventListener('click', () => {
        const organizationCode = nodeElement.id.split('-')[1];
        setActiveOrganization(organizationCode);
      });
    },
  );

  renderApps();
};

export const formInputId = {
  email: 'email',
  password: 'password',
} as const;
