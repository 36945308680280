import Notify from 'simple-notify';
import 'simple-notify/dist/simple-notify.css';

export class ToastService {
  private constructor() {}

  static #instance: ToastService;

  static get instance() {
    if (!ToastService.#instance) {
      ToastService.#instance = new ToastService();
    }

    return ToastService.#instance;
  }

  #showToast(options = {}) {
    new Notify({
      effect: 'fade',
      speed: 300,
      showIcon: true,
      showCloseButton: true,
      autoclose: true,
      autotimeout: 6 * 1000,
      notificationsGap: 20,
      notificationsPadding: 20,
      position: 'right top',
      ...options,
    });
  }

  showErrorToast(text: string, options = {}) {
    this.#showToast({
      ...options,
      status: 'error',
      title: 'Error',
      text,
    });
  }
}
