export const getElement: {
  <T extends Element = HTMLElement>(
    querySelector: string,
    optional: true,
  ): T | undefined;
  <T extends Element = HTMLElement>(querySelector: string, optional: false): T;
  <T extends Element = HTMLElement>(
    querySelector: string,
    optional?: undefined,
  ): T;
} = <T extends Element = HTMLElement>(
  querySelector: string,
  optional?: boolean,
) => {
  const required = optional === false;
  const element = document.querySelector<T>(querySelector);
  if (required && !element) {
    throw new Error(`Element with query selector ${querySelector} not found`);
  }

  return element;
};
