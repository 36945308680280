import { ApplicationDto } from '../types/ApplicationDto';
import { ConfigDto } from '../types/ConfigDto';
import { ApiService } from './apiService';
import { getConfig } from '../config.ts';

const defaultOrganization = 'TF';
const defaultApplication = 'ERP';
const params = new URLSearchParams(window.location.search);

if (params.get('application')) {
  localStorage.setItem('activeApplication', params.get('application')!);
}

if (params.get('organization')) {
  localStorage.setItem('organizationCode', params.get('organization')!);
}

export class ConfigService {
  private constructor() {}

  static #instance: ConfigService;

  readonly loginPermission = 'LOGIN';
  #firebaseConfigs?: ConfigDto[];
  #applications?: ApplicationDto[];
  #activeApplication: string = localStorage.getItem('activeApplication')
    ? localStorage.getItem('activeApplication')!
    : defaultApplication;
  #organizationCode: string = localStorage.getItem('organizationCode')
    ? localStorage.getItem('organizationCode')!
    : defaultOrganization;

  public allowedOrigins: string[] = [];

  /**
   * Loads the allowed origins into the `allowedOrigins` property from the configuration.
   *
   * @return {void} This method does not return a value.
   */
  loadAllowedOrigins(): void {
    this.allowedOrigins = [getConfig().TF_ERP_URL, getConfig().TF_ADM_URL];
  }

  /**
   * Retrieves the singleton instance of ConfigService.
   *
   * This ensures that only one instance of ConfigService is created and shared
   * across the application, providing a consistent configuration service.
   *
   * @return {ConfigService} The singleton instance of ConfigService.
   */
  static get instance(): ConfigService {
    if (!ConfigService.#instance) {
      ConfigService.#instance = new ConfigService();
    }

    return ConfigService.#instance;
  }

  /**
   * Retrieves the currently active application for the organization.
   * If the active application is not set or is invalid, it sets the active application
   * to the first available application's name.
   *
   * @return {string} The name of the active application for the organization.
   */
  get activeApplication(): string {
    if (!this.availableApps) {
      return this.#activeApplication;
    }

    const activeAppName = this.availableApps.map((app) => app.name);
    const isActiveApplicationValid =
      this.#activeApplication &&
      activeAppName.includes(this.#activeApplication);

    if (!isActiveApplicationValid) {
      this.#activeApplication = this.availableApps[0].name;
    }

    return this.#activeApplication;
  }

  get organizationCode(): string {
    return this.#organizationCode;
  }

  get activePermissionPrefix() {
    return this.availableApps?.find(
      (app) => app.name === this.activeApplication,
    )?.permissionPrefix;
  }

  get availableApps() {
    if (!this.#applications) {
      return null;
    }

    return this.#applications.filter((app) =>
      app.allowedTenants.includes(this.organizationCode),
    );
  }

  /**
   * Sets the active application to the specified application.
   *
   * @param {string} application - The name of the application to set as active.
   * @return {void}
   */
  setActiveApplication(application: string): void {
    localStorage.setItem('activeApplication', application);
    this.#activeApplication = application;
  }

  /**
   * Sets the organization code to the specified application.
   *
   * @param {string} code - The code of the organization to set.
   * @return {void}
   */
  setOrganizationCode(code: string): void {
    localStorage.setItem('organizationCode', code);
    this.#organizationCode = code;
  }

  /**
   * Loads the Firebase and Application configurations concurrently and assigns them to instance properties.
   *
   * @return {Promise<void>} A promise that resolves when configurations are loaded and assigned.
   */
  async #loadConfig(): Promise<void> {
    const [firebase, applications] = await Promise.all([
      ApiService.instance.getFirebaseConfig(),
      ApiService.instance.getApplicationConfig(),
    ]);

    this.#firebaseConfigs = firebase;
    this.#applications = applications;
  }

  /**
   * Retrieves the Firebase configuration for the current organization.
   * Throws an error if the organization code is not defined or if no valid configurations are found.
   *
   * @return {Promise<Object>} The Firebase configuration object for the specified organization.
   * @throws {Error} If the organization code is not defined.
   * @throws {Error} If no valid Firebase configurations are found for the organization.
   */
  async getConfig(): Promise<ConfigDto> {
    if (!this.organizationCode) {
      throw new Error('Organization code not defined');
    }

    if (!this.#firebaseConfigs) {
      await this.#loadConfig();
    }

    const config = this.#firebaseConfigs?.find(
      (config) => config.organization.code === this.organizationCode,
    );

    if (!config) {
      throw new Error(
        `No valid firebase configs found for organization ${this.organizationCode}`,
      );
    }

    return config;
  }

  /**
   * Fetches the Firebase configuration settings.
   * If the configuration is not already loaded, it triggers the loading process.
   *
   * @return {Promise<ConfigDto[]>} A promise that resolves to an array of Firebase configuration objects.
   */
  async getFirebaseConfigs(): Promise<ConfigDto[]> {
    if (!this.#firebaseConfigs) {
      await this.#loadConfig();
    }

    return this.#firebaseConfigs!;
  }

  /**
   * Retrieves the list of applications.
   *
   * @return {Promise<ApplicationDto[]>} A promise that resolves to an array of ApplicationDto objects.
   */
  async getApps(): Promise<ApplicationDto[]> {
    if (!this.#applications) {
      await this.#loadConfig();
    }

    return this.#applications!;
  }
}
