import { ApplicationManyResponseDto } from '../types/ApplicationManyResponseDto';
import { FirebaseConfigResponse } from '../types/FirebaseConfigResponse';
import { ConfigService } from './configService';
import { getConfig } from '../config.ts';
import { ConfigDto } from '../types/ConfigDto.ts';
import { LoginToAGWResponse } from '../types/common';

const ALLOWED_TENANTS = ['TF', 'TIO', 'RTSG'];
export class ApiService {
  private constructor() {}

  static #instance: ApiService;

  static get instance() {
    if (!ApiService.#instance) {
      ApiService.#instance = new ApiService();
    }

    return ApiService.#instance;
  }

  async loginToAGW(idToken: string): Promise<LoginToAGWResponse> {
    const response = await this.#fetch(
      `/${ConfigService.instance.organizationCode}/auth/login`,
      {
        method: 'POST',
        body: JSON.stringify({ idToken }),
        headers: {
          'Content-Type': 'application/json',
          'tfpl-id-token': idToken,
        },
      },
    );

    return {
      ...response.data,
      token: idToken,
      tenant: ConfigService.instance.organizationCode,
    };
  }

  async getApplicationConfig() {
    const response: ApplicationManyResponseDto =
      await this.#fetch('/application');
    return response.data;
  }

  /**
   * Fetches the Firebase configuration for allowed tenants.
   *
   * @returns {Promise<ConfigDto[]>} A promise that resolves to an array of Firebase configurations for tenants that are allowed.
   */
  async getFirebaseConfig(): Promise<ConfigDto[]> {
    const response: FirebaseConfigResponse = await this.#fetch(
      '/auth/firebase-config',
    );
    return response.data.filter((config) =>
      ALLOWED_TENANTS.includes(config.organization.code),
    );
  }

  async #fetch(path: string, options?: RequestInit) {
    const response = await fetch(`${getConfig().TF_AGW_URL}${path}`, {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options ?? {}),
    });
    return response.json();
  }
}
