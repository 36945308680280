import { ConfigService } from './configService';
import { getConfig } from '../config.ts';

export class RedirectService {
  private constructor() {}

  static #instance: RedirectService;

  static get instance() {
    if (!RedirectService.#instance) {
      RedirectService.#instance = new RedirectService();
    }

    return RedirectService.#instance;
  }

  #getAppUrl() {
    const app = ConfigService.instance.activeApplication?.toLowerCase();
    switch (app) {
      case 'erp':
        return getConfig().TF_ERP_URL;
      case 'admin':
        return getConfig().TF_ADM_URL;
      default:
        throw new Error(`Application ${app} is not supported`);
    }
  }

  redirectToApp() {
    window.location.href = this.#getAppUrl();
  }
}
