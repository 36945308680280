const defaultValue = 'default value from config.ts, change me pls';
let config = {
  TF_AGW_URL: import.meta.env.VITE_TF_AGW_URL
    ? import.meta.env.VITE_TF_AGW_URL
    : defaultValue,
  TF_ERP_URL: import.meta.env.VITE_TF_ERP_URL
    ? import.meta.env.VITE_TF_ERP_URL
    : defaultValue,
  TF_ADM_URL: import.meta.env.VITE_TF_ADM_URL
    ? import.meta.env.VITE_TF_ADM_URL
    : defaultValue,
  FIREBASE_EMULATOR: import.meta.env.VITE_FIREBASE_EMULATOR
    ? import.meta.env.VITE_FIREBASE_EMULATOR
    : defaultValue,
  FIREBASE_USE_AUTH_EMULATOR: import.meta.env.VITE_FIREBASE_USE_AUTH_EMULATOR
    ? import.meta.env.VITE_FIREBASE_USE_AUTH_EMULATOR
    : defaultValue,
};

export interface Config {
  TF_AGW_URL: string;
  TF_ERP_URL: string;
  TF_ADM_URL: string;
  FIREBASE_EMULATOR: string;
  FIREBASE_USE_AUTH_EMULATOR: string;
}
export const setConfig = (configData: Config): void => {
  config = configData;
};

export const getConfig = (): Config => config;
