import isEmail from 'validator/es/lib/isEmail';
import isEmpty from 'validator/es/lib/isEmpty';

import './style.css';
import { renderLoginForm, formInputId } from './components/loginForm.ts';
import { AuthService } from './services/authService.ts';
import { onAuthStateChanged, getRedirectResult } from 'firebase/auth';
import { getElement } from './utls/getElement.ts';
import { ConfigService } from './services/configService.ts';
import { getConfig, setConfig } from './config.ts';

let authService: AuthService | null = null;

const showLoginForm = async () => {
  if (window.parent === window.top) {
    await renderLoginForm(
      document.querySelector<HTMLDivElement>('#ula-app') as HTMLDivElement,
    );
  }
};

const getFormInputErrorElement = (formName: keyof typeof formInputId) =>
  getElement<HTMLDivElement>(`#${formName}-error`);

const validateForm = ({
  email,
  password,
}: Record<keyof typeof formInputId, string>) => {
  const errors: { input: keyof typeof formInputId; message: string }[] = [];

  if (!isEmail(email)) {
    errors.push({
      input: formInputId.email,
      message: 'Email must have valid format',
    });
  }

  if (isEmpty(email)) {
    errors.push({ input: formInputId.email, message: 'Email is required' });
  }

  if (isEmpty(password)) {
    errors.push({
      input: formInputId.password,
      message: 'Password is required',
    });
  }

  errors.forEach((error) => {
    const errorPlaceholder = getFormInputErrorElement(error.input);
    errorPlaceholder.innerHTML = error.message;
    errorPlaceholder.classList.add('opacity-100');
    errorPlaceholder.classList.remove('opacity-0');
  });

  Object.values(formInputId)
    .filter((formInputId) => {
      return errors.findIndex((error) => error.input === formInputId) === -1;
    })
    .forEach((inputId) => {
      const errorElement = getFormInputErrorElement(inputId);
      errorElement.classList.add('opacity-0');
      errorElement.classList.remove('opacity-100');
    });

  if (errors.length > 0) {
    throw new Error(`Validation errors: ${JSON.stringify(errors)}`);
  }
};

const addLoginListeners = (
  signInForm: HTMLFormElement,
  googleLogin: HTMLButtonElement,
) => {
  if (window.parent === window.top) {
    signInForm.addEventListener('submit', async (e) => {
      e.preventDefault();
      const email = signInForm[formInputId.email].value;
      const password = signInForm[formInputId.password].value;

      validateForm({ email, password });

      authService?.loginWithPassword(email, password);
    });

    googleLogin.addEventListener('click', async (e: MouseEvent) => {
      e.preventDefault();
      await authService?.loginWithGoogle();
    });
  }
};

const removeLoginListeners = (
  signInForm?: HTMLFormElement,
  googleLogin?: HTMLButtonElement,
) => {
  signInForm?.removeEventListener('submit', () => {});
  googleLogin?.removeEventListener('click', () => {});
};

const sendUserInfo = () => {
  const message = JSON.parse(JSON.stringify(authService?.loggedUser));
  ConfigService.instance.allowedOrigins.forEach((origin) => {
    window.parent.postMessage(message, origin);
  });
};

window.onmessage = async (event) => {
  if (ConfigService.instance.allowedOrigins.includes(event.origin)) {
    if (event.data === 'logout') {
      await authService?.logout();
    }

    if (event.data === 'getUserInfo') {
      sendUserInfo();
    }
  }
};

const start = async () => {
  const nodeEnv = import.meta.env.MODE;
  if (nodeEnv === 'development') {
    console.log('Starting vue app in development mode with config');
    console.log(getConfig());
  } else {
    try {
      const response = await fetch('/config');
      setConfig(await response.json());
      console.log('-> Config fetched from server');
      console.log(getConfig());
    } catch (e) {
      console.error('Can not fetch config from server');
      console.error(e);
    }
  }

  ConfigService.instance.loadAllowedOrigins();

  authService = AuthService.instance;
  authService.initFirebase();
  await authService.initialized;

  const loginResult = await getRedirectResult(authService.auth);
  if (loginResult) {
    await authService.handleLoginResponse(loginResult);
  }

  await showLoginForm();
  addLoginListeners(
    getElement<HTMLFormElement>('#loginForm'),
    getElement<HTMLButtonElement>('#googleLogin'),
  );

  onAuthStateChanged(authService.auth, async (user) => {
    if (user) {
      const idToken = await user.getIdToken();
      await authService?.refreshAGWLogin(idToken);
      if (window.parent === window.top) {
        removeLoginListeners(
          getElement<HTMLFormElement>('#loginForm', true),
          getElement<HTMLButtonElement>('#googleLogin', true),
        );
      }
    } else {
      await authService?.logout();
    }
    sendUserInfo();
  });
};

start();
